import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useBreak } from "hooks/useBreak";
import {
  active_project,
  current_language,
  currentActiveProject,
} from "state/store.global";

import { getFocus, useAtom, useGet, useSet } from "state/jotai";
import { _project } from "state/store.projects";
import { _bootstrap } from "state/store.bootstrap";
import Dropdown from "components/mui/Dropdown";
import DropdownItem from "components/mui/DropdownItem";
import { Main, Option, Cell, Text } from "./Dropdown2.Foldout.style";

export const Dropdown2 = () => {
  const dropdownRef = useRef(null);
  const setActiveID = useSet(active_project);
  const [state, setState] = useState(false);
  const currentLanguage = useAtom(current_language)[0];
  const dropdownOptions = getFocus(_bootstrap, "projectnames");
  const {
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const isDesktop = useBreak("md_up");
  const location = useLocation().pathname.replace("/" + language, "");
  // const face      = options().find( i => location == i.path )
  const [activeProject, setActiveProject] = useAtom(currentActiveProject);
  const [face, setFace] = useState("");

  useEffect(() => {
    if (Object.keys(dropdownOptions).length && pid) {
      setFace(
        dropdownOptions[currentLanguage].filter(d => d.PID === pid)[0]?.URLSafe
      );
    }
  }, [pid, currentLanguage]);
  const click = () => setState(!state);

  const handleSelect = () => {
    dropdownRef.current.closeMenu();
  };

  return (
    <>
      <Dropdown
        ref={dropdownRef}
        {...(isDesktop && {
          color: "#ffffff",
          fontWeight: "600",
          fontSize: "18px",
          lineHeight: "21px",
        })}
        xs="0"
        {...(!isDesktop && { marginLeft: "-16px !important" })}
        buttonText={
          isDesktop
            ? buttonsLabel?.filter(
                btn => btn.UILabelProperty === "VælgVinkel"
              )[0]?.Label
            : ""
        }
        face={isDesktop ? `: ${face}` : face}
      >
        {/* {console.log(dropdownOptions,"options")} */}
        {dropdownOptions[currentLanguage].map(
          ({ path, Presentation, URLSafe, PID, PIN }, key) => {
            //const active = URLSafe == face ? "true" : "false";
            const click = () => {
              setActiveID({
                pid: PID,
                pin: PIN,
              });
              navigate("/" + language + path);
              setActiveProject(Presentation);
              setTimeout(() => {
                setState(false);
              }, 150);
            };
            if (PIN <= 6) {
            return (
              <DropdownItem key={key} clr={clr} padding="0px !important" >
                <Cell
                  onClick={() => {
                    handleSelect();
                    click(URLSafe);
                  }}
                >
                  <Text clr={clr}>{URLSafe}</Text>
                </Cell>
              </DropdownItem>
            );
          } else {
            return null;
          }
          }
        )}
      </Dropdown>
    </>
  );
};
