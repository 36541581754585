import styled from 'styled-components/macro'

export const Tiny = ({tiny, dim, status, percent}) => {


  const blur = Math.abs(percent-100)/3
  const opac = percent/100
  return (
    <>
      { status !== 'DONE' &&
        <StyledTiny
          src={tiny}
          w={dim.W} 
          h={dim.H} 
          alt={''}
          style={{filter:`blur(${blur}px)`,opacity:opac}}/>
      }
    </>
  )

}

const StyledTiny = styled.img`
  width: ${({ w }) => w}px;
  height: ${({ h }) => h}px;
  /* filter: ${({ blur }) => `blur(${blur}px)`}; */
  position: absolute;
`