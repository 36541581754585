import { useEffect }      from 'react'
import { useFetch }       from 'hooks/fetch/useFetch'
import { useAtom, useSetAtom }     from 'state/jotai'
import { _bootstrap}      from 'state/store.bootstrap'
import { BOOT }           from 'api/api'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { iframeMode } from 'state/store.global'

export const useFetch_Bootstrap = () => {
  const navigate            = useNavigate();
  const location            = useLocation();
  const { i18n:{language} } = useTranslation()
  const path                = location.pathname.split('/')
  const setIframeMode       = useAtom(iframeMode)[1];

  const updateAtom   = useSetAtom(_bootstrap)
  const { D:data }   = useFetch({url:BOOT,file:'useFetch_Bootstrap.jsx'})
  const { Projects } = Object.keys(data || {}).length ? data : {};
  const state        = Object.keys(data || {}).length ? true : false

 

  const projectDropdown = {};
  const projectLanguage = data?.multiProjectOption ? [...new Set(data?.multiProjectOption[0]?.map( m => m.Language))] : [];

  // if the given path includes language which isn't proper then the page will redirect to default language path
  if(projectLanguage && projectLanguage?.length && !projectLanguage.includes(language)) {
    navigate(`/${projectLanguage[0]}/${path.slice(2).join('/')}`, { replace: "true" });
  }

  // Multiproject dropdown adding as per different langauge 
  projectLanguage.forEach(( lang ) => {
  
    projectDropdown[lang] = data.multiProjectOption[0].filter((m) => m.Language === lang).map((p, i) => {

      let projectData = Projects.filter((pr) => pr.ProjectID === p.PID)
      if(projectData.length > 0) return { ...p, PIN: i, available_Units: projectData[0]?.NoOfVacantUnits }
      return { ...p, PIN: i}
    } )
  })
// console.log(projectDropdown,"droppp")
// console.log(projectLanguage,"lang")
  useEffect(()=>{
    function iniFrame() {
      if(window.self !== window.top) {
        setIframeMode(true)
      } else {
        setIframeMode(false)
      }
    }
    iniFrame();

    if ( data ) {
      updateAtom({
        type        : data.HasMap ? 'mono' : 'poly',
        cdn         : data.CDNIsometry,
        openhouse   : data.ShowOpenHouseInfo,
        siteid      : data.SiteID,
        sitename    : data.SiteName,
        routes      : data.Router,
        startpage   : data.StartPage ?? `${language}/isometry`,
        dropdown    : data.Dropdown,
        projectids  : [data.ProjectIDs.join(','), ...data.ProjectIDs],
        projectnames: { ...projectDropdown },
        languages   : projectLanguage,
       
      })
      // console.log(data,"data")
      // console.log([data?.ProjectIDs?.join(','), ...data?.ProjectIDs])
    }
  },[data])
// console.log(data,"idd")
  return state

}